// FONTS
// Sans Serif: Roboto, Open Sans, Lato, Raleway, Montserrat
// Serif: Libre Baskerville, Merriweather

@use "sass:math";

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Roboto+Condensed:300,400,700|Open+Sans:400,700|Lato:400,700|Raleway:400,700|Montserrat:400,700|Libre+Baskerville:400,700|Merriweather:400,700');

.font-roboto {
  font-family:
    'Roboto',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-opensans {
  font-family:
    'Open Sans',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-lato {
  font-family:
    'Lato',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-raleway {
  font-family:
    'Raleway',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-montserrat {
  font-family:
    'Montserrat',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-librebaskerville {
  font-family:
    'Libre Baskerville',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-merriweather {
  font-family: 'Merriweather',
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

$text-xsmall: 12px;
$text-small: 14px;
$text-normal: 16px;
$text-medium: 20px;
$text-large: 24px;
$text-jumbo: 46px;

@mixin base-font {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

@mixin base-sans-serif-font {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin base-google-font {
  font-family: 'Roboto', sans-serif;
}

@mixin base-font-size {
  font-size: $text-small;
}

// NUMBERS & CALCULATIONS
$two: 2;
$three: 3;
$four: 4;
$five: 5;
$six: 6;
$hundred: 100%;

$twelve-hundredth: math.div(100%, 12);
$twelve-hundredth-by-two: math.div(100%, 12) * 2;

$eight-hundredth: 100% * 0.125;
$eight-hundredth-by-two: (100% * 0.125) * 2;

$six-hundredth: math.div(100%, 6);
$six-hundredth-by-two: math.div(100%, 6) * 2;
$six-hundredth-by-four: math.div(100%, 6) * 4;

$three-hundredth: math.div(100%, 3);
$three-hundredth-by-two: math.div(100%, 3) * 2;
$three-hundredth-by-four: math.div(100%, 3) * 4;
$three-hundredth-by-eight: math.div(100%, 3) * 8;

$desktop-min: 641px;

// COLORS
$white: #ffffff;
$black: #000000;
$gray: #555555;
$light-gray: #f7f7f7;
$medium-gray: #999999;
$dark-gray: #333333;

$chromakey-green: #00ff00;
$chromakey-blue: #0000ff;

$brand-primary: #2bacca;
$text-primary: #38474b;
$text-secondary: #57757c;

$background-light: #f2f6fa;

$border-light-blue: #dfeaef;

$success-green: #00cf90;
$danger-red: #eb4e5b;
$warning-yellow: #e0b365;

@mixin info {
  background-color: lighten($brand-primary, 45%);
  color: $brand-primary;
}

@mixin success {
  background-color: lighten($success-green, 60%);
  color: $success-green;
}

@mixin warning {
  background-color: lighten($warning-yellow, 30%);
  color: $warning-yellow;
}

@mixin danger {
  background-color: lighten($danger-red, 35%);
  color: $danger-red;
}

// PRODUCTION COLORS
$prod-blue: #4f65a1;
$prod-light-blue: #44506e;
$prod-medium-blue: #283142;
$prod-dark-blue: #20252e;

// LINK COLORS
$brandLink: darken($brand-primary, 10%);
$brandLinkHover: $brand-primary;

$brandLinkAlt: $brand-primary;
$brandLinkAltHover: lighten($brand-primary, 10%);

$darkLink: lighten($gray, 20%);
$darkLinkHover: darken($gray, 10%);

$lightLink: rgba($white, .9);
$lightLinkHover: $white;

$dangerLink: darken($danger-red, 15%);
$dangerLinkHover: $danger-red;

// TAGBOARD LOGOS
$poweredby-brand: url(/live/assets/img/logo-poweredby-brand.svg);
$poweredby-white: url(/live/assets/img/logo-poweredby-white.svg);
$poweredby-gray: url(/live/assets/img/logo-poweredby-gray.svg);
$poweredby-black: url(/live/assets/img/logo-poweredby-black.svg);

$poweredby-inline-brand: url(/live/assets/img/logo-poweredby-inline-brand.svg);
$poweredby-inline-white: url(/live/assets/img/logo-poweredby-inline-white.svg);
$poweredby-inline-gray: url(/live/assets/img/logo-poweredby-inline-gray.svg);
$poweredby-inline-black: url(/live/assets/img/logo-poweredby-inline-black.svg);

$tagboard-logo-brand: url(/live/assets/img/logo-tagboard-brand.svg);
$tagboard-logo-white: url(/live/assets/img/logo-tagboard-white.svg);
$tagboard-logo-gray: url(/live/assets/img/logo-tagboard-gray.svg);
$tagboard-logo-black: url(/live/assets/img/logo-tagboard-black.svg);

$tagboard-embeds-logo: url(/live/assets/img/logo-tagboard-embeds.svg);
$tagboard-embeds-logo-stacked: url(/live/assets/img/logo-tagboard-embeds-stacked.svg);

$hash-t-brand: url(/live/assets/img/logo-hash-t-blue.svg);
$hash-t-white: url(/live/assets/img/logo-hash-t-white.svg);

// TRANSITIONS
@mixin all-transition {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  transition: all 200ms ease;
}

@mixin opacity-transition {
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

@mixin color-transition {
  -webkit-transition: color 200ms ease;
  -moz-transition: color 200ms ease;
  -ms-transition: color 200ms ease;
  transition: color 200ms ease;
}

@mixin background-transition {
  -webkit-transition: background-color 200ms ease;
  -moz-transition: background-color 200ms ease;
  -ms-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}

@mixin box-shadow-transition {
  -webkit-transition: box-shadow 200ms ease;
  -moz-transition: box-shadow 200ms ease;
  -ms-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

@mixin btn-transition {
  -webkit-transition: color 200ms ease, border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;
  -moz-transition: color 200ms ease, border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;
  -ms-transition: color 200ms ease, border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;
}

// NETWORKS
$twitter-color: #000;
$twitter-logo: url(/live/assets/img/twitterx.svg);
$twitter-logo-white: url(/live/assets/img/twitterx-white.svg);
$twitter-verified-icon: url(/live/assets/img/twitter-verified-icon.svg);
$twitter-verified-org: url(/live/assets/img/twitter-verified-gold.svg);
$twitter-verified-gov: url(/live/assets/img/twitter-verified-gov.svg);

@mixin twitter-logo-icon {
  content: "\e92a" ;
}

$facebook-color: #1877F2;
$facebook-logo: url(/live/assets/img/logo-facebook.svg);
$facebook-logo-white: url(/live/assets/img/logo-facebook-white.svg);

@mixin facebook-logo-icon {
  content: "\e900";
}

$instagram-color: #ed4956;
$instagram-logo: url(/live/assets/img/logo-instagram.svg);
$instagram-logo-white: url(/live/assets/img/logo-instagram-white.svg);
$instagram-gradient: url(/live/assets/img/instagram-gradient.png);
$instagram-gradient-css: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);

@mixin instagramGradient {
  background-image: $instagram-gradient;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat
}

@mixin instagram-logo-icon {
  content: "\e903";
}

$youtube-color: #FF0000;
$youtube-flag-color: #EEEEEE;
$youtube-logo: url(/live/assets/img/logo-youtube.svg);
$youtube-logo-white: url(/live/assets/img/logo-youtube-white.svg);

@mixin youtube-logo-icon {
  content: "\e908";
}

$reddit-color: #FF4500;
$reddit-logo: url(/live/assets/img/logo-reddit.svg);
$reddit-logo-white: url(/live/assets/img/logo-reddit-white.svg);

@mixin reddit-logo-icon {
  content: "\e921";
}

$tiktok-color: #000000;
$tiktok-logo: url(/live/assets/img/logo-tiktok.svg);
$tiktok-logo-white: url(/live/assets/img/logo-tiktok-white.svg);
$tiktok-verified-icon: url(/live/assets/img/tiktok-verified-icon.svg);

@mixin tiktok-logo-icon {
  content: "\e923";
}

$twitch-color: #9146ff;
$twitch-color-lighter: #b57fff;
$twitch-logo: url(/live/assets/img/logo-twitch.svg);
$twitch-logo-white: url(/live/assets/img/logo-twitch-white.svg);

@mixin twitch-logo-icon {
  content: "\e92c";
}

$heymarket-color: #17191a;
$heymarket-color-lighter: #494949;
$heymarket-logo: url(/live/assets/img/logo-heymarket.svg);

@mixin heymarket-logo-icon {
  content: "\e92d";
}

$share-it-color: #00B4CC;
$share-it-logo: url(/live/assets/img/logo-share-it.svg);
$share-it-logo-white: url(/live/assets/img/logo-share-it-white.svg);

@mixin share-it-logo-icon {
  content: "\e92e";
}

// MIXINS
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin center-align {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transition: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin simple-center-align {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin vertical-align {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transition: translateY(-50%);
  transform: translateY(-50%);
}

@mixin horizontal-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transition: translateX(-50%);
  transform: translateX(-50%);
}

@mixin no-align {
  top: 0;
  left: 0;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transition: translate(0);
  transform: translate(0);
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin text-select {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@mixin fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin network-icon {
  position: relative;
  float: left;
  width: 1em;
  height: 1em;
  margin: 0 .3em;

  &:before {
    @include simple-center-align;
    font-size: 1em;
    width: 1em;
    height: 1em;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

@mixin network-icon-circular {
  position: relative;
  float: left;
  width: 1.15em;
  height: 1.15em;
  margin: 0 .2em;
  border-radius: 100%;

  &:before {
    @include simple-center-align;
    font-size: .5em;
    width: 1em;
    height: 1em;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.network-icon-twitter {
    background-color: $twitter-color;
  }

  &.network-icon-instagram {
    background-color: $instagram-color;
  }

  &.network-icon-facebook {
    background-color: $facebook-color;
  }

}

@mixin network-icon-square {
  position: relative;
  float: left;
  width: 1.15em;
  height: 1.15em;
  margin: 0 .2em;
  border-radius: .15em;

  &:before {
    @include simple-center-align;
    font-size: .5em;
    width: 1em;
    height: 1em;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.network-icon-twitter {
    background-color: $twitter-color;
  }

  &.network-icon-instagram {
    background-color: $instagram-color;
  }

  &.network-icon-facebook {
    background-color: $facebook-color;
  }
}

@mixin network-icon-styles {
  .cta-social-icon-style-simplified {
    .networks {
      width: auto;
      height: 1em;
    }

    .network-icon {
      @include network-icon;
    }
  }

  .cta-social-icon-style-circular {
    .networks {
      width: auto;
      height: 1.15em;
    }

    .network-icon {
      @include network-icon-circular;
    }
  }

  .cta-social-icon-style-square {
    .networks {
      width: auto;
      height: 1.15em;
    }

    .network-icon {
      @include network-icon-square;
    }
  }
}

@mixin display-header {
  .display-header {
    font-size: 4vmin;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16vmin;
    z-index: 2;

    .qr-module {
      position: relative;
      width: 10%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      container-name: qr-module;
      container-type: inline-size;

      .qr-code{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .title {
    position: absolute;
    width: auto;
    height: 1.5em;
    top: 0;
    bottom: 0;
    left: 5%;
    right: auto;
    margin: auto;
  }

  .logo {
    @include simple-center-align;
    width: 90%;
    height: 75%;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .networks {
    position: absolute;
    top: 4vmin;
    right: 5%;
  }

  @include network-icon-styles;

  .cta-template-1 {
    .has-qr-code {
      left: 10% !important;
    }
  }

  .cta-template-2 {
    .title {
      @include horizontal-align;
    }

    .logo {
      left: 5%;
      right: auto;
      background-position: 0 50%;
    }

    .display-header .qr-module {
      left: 20%;
      right: auto;
      background-position: 0 50%;
    }
  }

  .cta-template-3 {
    .title {
      top: 3vmin;
      bottom: auto;
    }

    .has-qr-code {
      left: 10% !important;
    }

    .logo {
      left: auto;
      right: 5%;
      background-position: 100% 50%;
    }

    .networks {
      display: none;
    }
  }

  .cta-template-4 {
    .title {
      top: 3vmin;
      bottom: auto;
      left: auto;
      right: 5%;
    }

    .has-qr-code {
      right: 10% !important;
    }

    .logo {
      left: 5%;
      right: auto;
      background-position: 0 50%;
    }

    .networks {
      display: none;
    }

    .display-header .qr-module {
      left: 90%;
      right: auto;
      background-position: 0 50%;
    }
  }

  .cta-template-5 {
    .title {
      top: 3vmin;
      bottom: auto;
      right: 5%;
      left: auto;
    }

    .has-qr-code {
      left: auto;
      right: 5%;
    }

    .networks {
      display: none;
    }
  }

  .sidebar.cta-template-5 {
    .title {
      top: 1vmin;
      bottom: auto;
      width: 70%;
      height: 1em;
      line-height: 1.25em;
    }

    .title.has-qr-code {
      left: auto;
      right: 0%;
    }

    .networks {
      display: none;
    }

    .display-header {
      height: 17vmin;
    }

    .display-header .qr-module {
      width: 30%;
      left: auto;
      right: 0%;
      background-position: 0 50%;
    }
    
  }

  .cta-template-6 {
    .title {
      top: 3vmin;
      bottom: auto;
    }

    .title.has-qr-code {
      left: 5%;
      right: auto;
    }

    .networks {
      display: none;
    }

    .display-header .qr-module {
      left: 91%;
      right: auto;
      background-position: 0 50%;
    }
  }

  .sidebar.cta-template-6 {
    .title {
      top: 1vmin;
      bottom: auto;
      width: 70%;
      height: 1em;
      line-height: 1.25em;
    }

    .has-qr-code {
      left: 5%;
      right: auto;
    }

    .networks {
      display: none;
    }

    .display-header {
      height: 17vmin;
    }

    .display-header .qr-module {
      width: 30%;
      left: 70%;
      right: auto;
      background-position: 0 50%;
    }
  }

  .hide-branding & {
    .networks {
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

@mixin display-header-small {
  .display-header {
    font-size: 3vmin;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.5vmin;
    z-index: 2;
  }

  .title {
    position: absolute;
    width: auto;
    height: 1.5em;
    top: 0;
    bottom: 0;
    left: 5%;
    right: auto;
    margin: auto;
  }

  .networks {
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: calc(5% + 12em);
    margin: auto;
  }

  @include network-icon-styles;
}

@mixin origin-flag-setup {
  text-align: center;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;

  &:before {
    @include simple-center-align;
    width: 1em;
    height: 1em;
  }
}

@mixin origin-flag-tagboard-classic {
  .origin-flag {
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: .1em .1em 0 .1em;
    color: $white;

    &:before {
      font-size: .8em;
    }

    &:after {
      content: "";
      position: absolute;
      top: 1.5em;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: .2em solid transparent;
      border-left: .3em solid transparent;
    }
  }

  .twitter .origin-flag {
    background-color: $twitter-color;

    &:before {
      @include twitter-logo-icon;
    }

    &:after {
      border-left-color: darken($twitter-color, 30%);
    }
  }

  .facebook .origin-flag {
    background-color: $facebook-color;

    &:before {
      @include facebook-logo-icon;
    }

    &:after {
      border-left-color: darken($facebook-color, 30%);
    }
  }

  .instagram .origin-flag {
    background-color: $instagram-color;

    &:before {
      @include instagram-logo-icon;
    }

    &:after {
      border-left-color: darken($instagram-color, 30%);
    }
  }

  .youtube .origin-flag {
    color: $youtube-color;
    background-color: $youtube-flag-color;
    background-image: radial-gradient(circle closest-side, #ffffff 28%, $youtube-flag-color 30%);

    &:before {
      @include youtube-logo-icon;
    }

    &:after {
      border-left-color: darken($youtube-flag-color, 30%);
    }
  }

  .twitch .origin-flag {
    background-color: $twitch-color;

    &:before {
      @include twitch-logo-icon;
    }

    &:after {
      border-left-color: darken($twitch-color, 30%);
    }
  }

  .heymarket .origin-flag {
    background-color: $heymarket-color;
    background-image: $heymarket-logo;
    background-position: 50% 50%;
    background-size: 90% auto;
    background-repeat: no-repeat;

    &:after {
      border-left-color: $heymarket-color-lighter;
    }

    .icon {
      display: none;
    }
  }

  .reddit .origin-flag {
    background-color: $reddit-color;

    &:before {
      @include reddit-logo-icon;
    }

    &:after {
      border-left-color: darken($reddit-color, 30%);
    }
  }

  .tiktok .origin-flag {
    background-color: $tiktok-color;

    &:before {
      @include tiktok-logo-icon;
    }

    &:after {
      border-left-color: lighten($tiktok-color, 30%);
    }
  }

  .share-it .origin-flag {
    background-color: $share-it-color;
    display: none;

    &:before {
      @include share-it-logo-icon;
    }

    &:after {
      border-left-color: darken($share-it-color, 30%);
    }
  }
}

@mixin origin-flag-simple {
  .origin-flag {
    position: absolute;
    width: 1em;
    height: 1em;
    color: $white;
  }

  .twitter .origin-flag {
    &:before {
      @include twitter-logo-icon;
    }
  }

  .facebook .origin-flag {
    &:before {
      @include facebook-logo-icon;
    }
  }

  .instagram .origin-flag {
    &:before {
      @include instagram-logo-icon;
    }
  }

  .youtube .origin-flag {
    &:before {
      @include youtube-logo-icon;
    }
  }

  .twitch .origin-flag {
    &:before {
      @include twitch-logo-icon;
    }
  }

  .heymarket .origin-flag {
    &:before {
      @include heymarket-logo-icon;
    }
  }

  .reddit .origin-flag {
    &:before {
      @include reddit-logo-icon;
    }
  }

  .tiktok .origin-flag {
    &:before {
      @include tiktok-logo-icon;
    }
  }
}

// GENERAL STYLES

body {
  @include base-font;
  color: $white;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

p {
  margin: 0;
}

a {
  color: $brandLinkAlt;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $brandLinkAltHover;
    text-decoration: none;
  }
}

span.emoji {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: text-bottom;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  width: 1.2em;
  height: 1.2em;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

img.emoji {
  width: 1em;
  height: 1em;
}

.text-center {
  text-align: center;
}

.text-xsmall {
  font-size: $text-xsmall;
}

.text-small {
  font-size: $text-small;
}

.text-normal {
  font-size: $text-normal;
}

.text-jumbo {
  font-size: $text-jumbo;
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-brand {
  color: $brand-primary;
}

.text-primary {
  color: $text-primary;
}

.text-secondary {
  color: $text-secondary;
}

.text-danger {
  color: $danger-red;
}

.text-warning {
  color: $warning-yellow;
}

.icon.left {
  margin-right: 5px;
}

.icon.right {
  margin-left: 5px;
}

.help-text {
  font-size: $text-xsmall;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: inherit;
  margin: 0;
}

.clearfix {
  @include clearfix;
}

.info {
  @include info;
}

.success {
  @include success;
}

.warning {
  @include warning;
}

.danger {
  @include danger;
}

.underline {
  text-decoration: underline;
}

.info-box {
  padding: 10px;
  background-color: $light-gray;

  &.info {
    @include info;
  }

  &.success {
    @include success;
  }

  &.warning {
    @include warning;
  }

  &.danger {
    @include danger;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.required-text {
  color: $danger-red;
}

// small margin
.margin-top-sm {
  margin-top: 10px;
}

.margin-bottom-sm {
  margin-bottom: 10px;
}

.margin-left-sm {
  margin-left: 10px;
}

.margin-right-sm {
  margin-right: 10px;
}

// medium margin
.margin-top-md {
  margin-top: 15px;
}

.margin-bottom-md {
  margin-bottom: 15px;
}

.margin-left-md {
  margin-left: 15px;
}

.margin-right-md {
  margin-right: 15px;
}

// large margin
.margin-top-lg {
  margin-top: 20px;
}

.margin-bottom-lg {
  margin-bottom: 20px;
}

.margin-left-lg {
  margin-left: 20px;
}

.margin-right-lg {
  margin-right: 20px;
}

// x-large margin
.margin-top-xl {
  margin-top: 30px;
}

.margin-bottom-xl {
  margin-bottom: 30px;
}

.margin-left-xl {
  margin-left: 30px;
}

.margin-right-xl {
  margin-right: 30px;
}

// no margin
.no-margin {
  margin: 0;
}

// element rules
.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-table {
  display: table;
}

.twitch {

  .timestamp{
    display: none;
  }

  .meta-author{
    font-size: .8em;

    .author{
      line-height: 1.2;
      font-size: 1em;
    }

    .handle{
      line-height: 1.2;
      font-size: .8em;
    }
  }

  .message{
    font-size: 100%;
  }

  .quote-reply {
    font-style: italic;
    margin-bottom: 2vmin;
  }

  .badges img, .emote img{
    width: 1em;
    margin-right: 5px;
  }

  .username {
    font-weight: bold;
    margin-right: 1vmin;

    &:after{
      content: ":";
    }
  }
}

.bold {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

//hide links
.posts{
  &.hide-links {
    .link {
      display: none;
    }
  }
}
