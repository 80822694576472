.ticker-v1_5 {
  @import "../shared/_defaults";
  @include display-header-small;
  @include origin-flag-simple;

  .fullscreen-wrapper {
    background-color: $chromakey-green;
  }

  .display-wrapper {
    top: auto;
    bottom: 0;
    font-size: 10vmin;
    height: 1em;
  }

  .display-header {
    font-size: .25em;
    height: 2em;
  }

  .posts {
    bottom: 0;
    left: 0;
    width: 100%;
    height: .5em;
    background-color: rgba($black, .5);
  }

  .post {
    font-size: .3em;
    line-height: 1.5;
    position: absolute;
    width: 100%;
    height: 100%;
    color: $white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    br {
      content: " ";
      display: inline;
      padding: .2em;
    }
  }

  .text-module {
    display: flex;
    float: right;
    width: calc(90% - 1.5em);
    height: 100%;
    margin-right: 5%;
    overflow: hidden;
  }

  .message {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
  }

  .post-scroll {
    white-space: nowrap;
    position: absolute;
  }

  .post-title,
  .post-text {
    display: inline-block;
    white-space: nowrap;
  }

  .post-title {
    font-weight: bold;
  }

  .meta-author {
    position: relative;
    padding-left: 2em;
    padding-right: 1em;
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }

    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .twitter .handle,
  .tiktok .handle {
    font-size: .8em;
    margin-left: .25em;
  }

  .reddit .subreddit + .handle {
    font-size: .8em;
    margin-left: .25em;
  }

  .origin-flag {
    top: 0;
    bottom: 0;
    left: 5%;
    right: auto;
    margin: auto;
  }

  // CUSTOMIZATIONS

  .post-color-theme-light {
    .posts {
      background-color: $white;
    }

    .post {
      color: $dark-gray;
    }

    .twitter .origin-flag {
      color: $twitter-color;
    }

    .instagram .origin-flag {
      color: $instagram-color;
    }

    .facebook .origin-flag {
      color: $facebook-color;
    }

    .youtube .origin-flag {
      color: $youtube-color;
    }

    .twitch .origin-flag {
      color: $twitch-color;
    }

    .heymarket .origin-flag {
      color: $heymarket-color;
    }

    .tiktok .origin-flag {
      color: $tiktok-color;
    }
  }

  .twitch{
    .meta-author{
      font-size: 1em;
    }

    .timestamp{
      display: none;
    }

    .post-text > span{
      display: flex;
    }

    .username{
      font-weight: bold;
      margin-right: 0.5em;
      
      &::after{
        content: ":";
      }
    }
  }

  .timestamp {
    font-size: .8em;
    line-height: 1.2;
    display: inline-block;
    margin-left: 8px;
    padding-left: 16px;
    border-left: 1px solid;
  }
}
